.sidebar__outer--mobile {
  
  width: 100%;
  .sidebar__toggle-button {
    display: none;
  }

  .sidebar {
    width: 100%;
  }

  .sidebar__mobile-toggle {
    position: absolute;
    right: 20px;
    top: -25px;
    z-index: 1000;

    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $main-shadow;
  }

  .sidebar__mobile-toggle:hover {
    background: $button-hover-color;
  }

  .sidebar__map-title {
    margin-top: 20px;
  }

  .sidebar__mobile-hide {
    font-weight: bold;
    font-size: 14px;
    color: #999999;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

    svg {
      margin-left: 8px;
    }
  }
}

.sidebar__outer--mobile-hidden {
  
  .sidebar__map-title,
  .sidebar,
  .sidebar__mobile-hide {
    display: none;
  }

  .sidebar__mobile-toggle {
    top: -225px;
  }
}

.sidebar__outer--mobile-collapsed {
  height: 200px;

  .sidebar__map-title {
    margin-top: 0px;
  }
}

.sidebar__outer--mobile-expanded {
  .sidebar__mobile-hide {
    display: none;
  }

  height: 100%;
  z-index: 1000;
  background: white;
}