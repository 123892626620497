@import '../variables';

.play {
  width: 90px;
  height: 90px;
  // background: white;
  position: absolute;
  left: 20px;
  top: -65px;
  z-index: 500;
  border-radius: 45px;
  // border: 2px solid black;
  box-shadow: $main-shadow;
  // border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  // box-shadow: $main-shadow;
  cursor: pointer;
  .svg-inline--fa {
    margin-bottom: 20px;
  }

  .CircularProgressbar {
    margin-top: 6px;
  }
}



.CircularProgressbar-path {
  stroke: $main-green !important;
}

.play:hover {
  // background: $button-hover-color;
}

@import './TimelinePlayButtonMobile.scss';