@import '../variables';

$top-margin: 30px;

.header {
  background: $header-fill;
  display: flex;
  height: $header-height;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.header__left,
.header__title,
.header__about {
  flex: 1;
  padding: 0 $top-margin;
}

.header__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 500px;
}

.header__maps {
  font-weight: bold;
  left: $top-margin;
  font-size: 18px;
  cursor: pointer;
  margin-right: 15px;
  color: white;
}

.header__about {
  font-weight: bold;
  right: $top-margin;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: white;
}

.header__about-text {
  margin-left: 8px;
}

.header__title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: white;
}

.header__maps-icon {
  margin-left: 8px;
}

@import './HeaderMobile.scss';