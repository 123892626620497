.atlas-nav--mobile {
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .atlas-nav__button {
    width: 100%;
  }

  .atlas-nav__prev {
    margin-left: 20px;
    margin-right: 7px;
  }

  .atlas-nav__next {
    margin-left: 7px;
    margin-right: 20px;
  }
}

