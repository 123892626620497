.header--mobile {

  .header__back,
  .header__legend {
    position: absolute;
    font-weight: bold;
    left: $top-margin;
    font-size: 18px;
  }
  
  .header__maps {
    width: 100%;
    text-align: center;
  }
  
  .header__about {
    font-size: 18px;
  }

  .header__legend-title {
    font-weight: bold;
    font-size: 18px;
  }
}



