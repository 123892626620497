@import '../variables';

$about-header-height: 60px;
$about-container-height: 620px;

.about {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  display: flex;
  justify-content: center;
}

.about__inner {
  width: 850px;
  height: $about-container-height;
  background: white;
  box-shadow: $main-shadow;
}

.about__close-button {
  position: absolute;
  right: 50px;
  top: 50px;
  color: white;
  font-size: 50px;
  cursor: pointer;
}

.about__header {
  width: 100%;
  height: $about-header-height;
  font-size: 20px;
  font-weight: bold;
  background: $header-fill;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__content {
  width: 100%;
  height: $about-container-height - $about-header-height;
  overflow-y: auto;
}

.about__image {
  width: 100%;
  height: 244px;
  background-image: url('./map.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
}

.about__narrative {
  padding-left: 25px;
  padding-right: 25px;
  // padding-top: 10px;
  padding-top: 75px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 28px;
  font-family: adobe-garamond-pro, serif;
  overflow: hidden;
}

.about__go-to-row {
  position: absolute;
  bottom: -50px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.about__go-to {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background: $main-green;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $main-shadow;
  color: white;
  flex-direction: column;
  cursor: pointer;
}

.about__go-to:hover {
  background: rgb(53, 136, 129);
}

.about__go-to-icon {
  font-size: 20px;
}

.about__go-to-text {
  font-size: 14px;
  font-weight: bold;
}

.about__citations a {
  font-size: 14px;
  line-height: 18px;
  display: block;
}

.about__narrative img {
  max-width: 100%;
  max-height: 100px;
  display: block;
  margin: auto;
}

@import './AboutMobile.scss';