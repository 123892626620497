@import '../variables';

.legend {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  flex-wrap: wrap;
}

.legend__actors-label {
  margin-left: 20px;
}

.legend--no-events {
  .legend__actors-label {
    margin-left: 0px;
  }
}

.legend__label {
  font-weight: bold;
  margin-right: 10px;
}

.legend__event,
.legend__actor {
  margin-left: 8px;
  margin-right: 8px;
}

.legend__actor {
  display: flex;
  align-items: center;
}

.legend__icon {
  margin-right: 5px;
}

.legend__swatch {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

@import './LegendMobile.scss';