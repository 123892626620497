@import '../variables';

.atlas-nav {
  display: flex;
  top: 15px;
  left: 35px;
  z-index: 500;
  pointer-events: auto;
}

.atlas-outer .atlas-nav {
  position: absolute;
}

.atlas-nav__button {
  height: 40px;
  width: 100px;
  background: white;
  box-shadow: $main-shadow;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.atlas-nav__button:hover {
  background: $button-hover-color;
}

.atlas-nav__prev {
  margin-right: 15px;
}

.atlas-nav.atlas-nav--sidebar-collapsed {
  left: 80px;
}

$atlas-nav__icon-spacing: 11px;

.atlas-nav__icon-left {
  margin-right: $atlas-nav__icon-spacing;
}

.atlas-nav__icon-right {
  margin-left: $atlas-nav__icon-spacing;
}

@import './AtlasNavButtonsMobile.scss';