.about--mobile {
  .about__close-button {
    display: none;
  }

  .about__inner {
    height: 100%;
    width: 100%;
  }

  .about__content {
    height: calc(100% - #{$header-height});
  }

  .about__narrative {
    padding-bottom: 25px;
  }
}