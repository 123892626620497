
.atlas-outer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.atlas {
  width: 100%;
  display: flex;
  flex: 1;
}

.atlas__event-svg {
  position: absolute;
  left: 0px;
  top: 0px;
}

.atlas__event-icon {
  border-radius: 25px;
  
  color: black;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  i {
    z-index: 200;
  }
}
.atlas__div-icon-outer--selected,
.atlas__div-icon-outer--highlighted {
  // background: rgba(0,0,0,0.5);
  // border: 4px solid black;
  // border-radius: 50px;
  // box-shadow: 0px 0px 0px 4px #94A19F;
  // border-radius: 27px;
  .atlas__event-circle {
    stroke: black;
    stroke-width: 8px;
  }
}

.atlas__div-icon-content {
  background: rgba(255,255,255,0.0);
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: 4px solid rgba(255, 255, 255, 0.0);
  // color: red;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  -webkit-text-stroke-color: rgba(255,255,255,0.6);
  -webkit-text-stroke-width: 2px;
}

// .atlas__div-icon-content:hover {
//   background: rgba(0,0,0,0.5);
//   border: 4px solid rgba(0, 0, 0, 0);
// }



.atlas__icon {
  line-height: 10px;
}

.atlas__polygon:hover {
  fill-opacity: .45;
}

$button-width: 30px;

.atlas__home-button {
  position: absolute;
  top: 80px;
  right: 10px;
  width: $button-width;
  height: $button-width;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.35);
  z-index: 2000;
  font-size: 12px;
}

.atlas__home-button:hover,
.atlas__home-button--deactivated {
  background: #f4f4f4;
}

.atlas__home-button--deactivated {
  color: #bbb;
  cursor: auto;
  pointer-events: none;
}

@import './AtlasMobile.scss';