@import '../variables';

.timeline {
  height: 138px;
  width: 100%;
  background: $header-fill;
  display: flex;
  margin: 0px;
  position: relative;
}

.timeline__inner {
  flex: 1 1 auto;
  padding: 0px;
}

.timeline__content {
  width: 100%;
  height: 100%;
}

.timeline__events-row,
.timeline__maps-row {
  height: 50%;
  display: flex;
}

.timeline__events-row > div:first-child,
.timeline__maps-row > div:first-child {
  color: white;
  margin: 10px;
  width: 75px;
}

.timeline__events-label {
  fill: white;
}

.timeline__tick {
  stroke: black;
  stroke-width: 1px;
  font-size: 12px;
}

.timeline__event,
.timeline__map {
  cursor: pointer;
}

.timeline__event {
  fill: white;
}


// .timeline__event:hover,
.timeline__map:hover,
.timeline__event--highlighted,
.timeline__event--selected,
.timeline__map--selected {
  fill: black;
}

.timeline__line {
  stroke: black;
  stroke-width: 0.5px;
}

@import './TimelineMobile.scss';