@import '../variables';

.maps-dropdown {
  position: absolute;
  width: 370px;
  background: white;
  box-shadow: $main-shadow;
  border-radius: 5px;
  z-index: 1000;
  max-height: 670px;
  overflow-y: auto;
}

.maps-dropdown__row {
  color: #1a1a1a;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  display: flex;
  // align-items: center;
}

.maps-dropdown__row-text {
  margin-left: 15px;
}

.maps-dropdown__row-era {
  font-weight: bold;
}

.maps-dropdown__row--selected,
.maps-dropdown__row:hover {
  color: $main-green;
}

@import './MapsDropdownMobile.scss';