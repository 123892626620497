.tooltip {
  min-width: 200px;
  max-width: 400px;
  position: fixed;
  background: #FFFFFF;
  pointer-events: none;
  border: 1px solid #999999;
  border-radius: 5px;
  z-index: 2000;
  margin: 15px;
  
}


.tooltip__inner {
  padding: 10px;
  
}

.tooltip__row {
  display: flex;
}

.tooltip__row:first-child {
  margin-top: 0px;
}

.tooltip__title {
  font-weight: bold;
  margin-top: 0px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip__field {
  font-weight: bold;
  margin-right: 8px;
}

.tooltip__prompt {
  font-style: italic;
  margin-top: 5px;
}