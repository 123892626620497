@import '../variables';

.app {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: hidden;
}

.app__content {
  display: flex;
  // flex-direction: column;
  height: calc(100% - 60px);
  width: 100%;
}

.app__atlas-content {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  // flex-shrink: 2;
  min-width: calc(#{$sidebar-min-width} - 100%);
}

@import './AppMobile';