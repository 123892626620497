@import '../variables';

.sidebar__outer {
  position: relative;
  // height: 100%;
  display: flex;
  flex-direction: column;
  width: 350px;
}

.sidebar__map-title {
  font-size: 18px;
  font-weight: bold;
  min-height: $header-height;
  // width: 80%;
  // margin-bottom: 30px;
  display: flex;
  align-items: center;
  // padding-left: 15px;
  // padding-right: 15px;
  padding-left: 30px;
  padding-right: 30px;
  // padding-top: 5px;
  // padding-bottom: 5px;
}

.sidebar__map-title-inner {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sidebar {
  width: 350px;
  // min-width: 350px;
  min-width: $sidebar-min-width;
  // overflow-y: scroll;
  overflow-y: auto;
  height: 100%;
  // height: calc(100% - #{$header-height});
  // position: relative;
}

.sidebar__outer.sidebar__outer--collapsed {
  width: 0px;

  .sidebar__map-title,
  .sidebar {
    display: none;
  }

  .sidebar__toggle-button {
    right: -65px;
  }
}

.sidebar__toggle-button {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: $main-shadow;
  background: white;
  top: 60px;
  z-index: 1000;
  right: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sidebar__toggle-button:hover {
  background: $button-hover-color;
}

.sidebar__inner {
  padding: 15px;
}

.sidebar__map-row {
  position: relative;
  margin-bottom: 20px;
}



.sidebar__read-more {
  
  position: absolute;
  bottom: 0px;
  
  
  pointer-events: none;
}

.sidebar__read-more,
.sidebar__read-less {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: $main-green;
}

.sidebar__read-more .fa-chevron-down,
.sidebar__read-less .fa-chevron-up {
  margin-left: 5px;
}

.sidebar__read-less {
  cursor: pointer;
}

.sidebar__map-description {
  max-height: 200px;
  font-family: adobe-garamond-pro, serif;
  padding-left: 15px;
  padding-right: 16px;
  overflow: hidden;
}

.sidebar__gradient {
  cursor: pointer;
  height: 75px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  width: 100%;
  bottom: 0px;
  // linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 50%, rgba(0,212,255,1) 100%);
}

.sidebar__map-row--expanded {
  .sidebar__gradient,
  .sidebar__read-more {
    display: none;
  }

  .sidebar__map-description {
    max-height: none;
  }
}

.sidebar__events-block-title {
  font-size: 12px;
  font-weight: bold;
}



.sidebar__event-row {
  padding: 15px;
  cursor: pointer;
}

.sidebar__event-row--selected {
  box-shadow: $main-shadow;
  border-radius: 5px;
  border-left: 4px solid black;
}

.sidebar__event-date {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.sidebar__event-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.sidebar__event-description {
  font-size: 14px;
  display: none;
}


.sidebar__event-row--selected .sidebar__event-description {
  display: block;
}

.sidebar__event-break {
  // margin-top: 15px;
  // margin-bottom: 15px;
  margin: 0px;
  background: #CCCCCC;
  height: 1px;
  border: none;
}

@import './SidebarMobile';