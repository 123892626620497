.legend--mobile {
  position: absolute;
  left: 0px;
  top: $header-height;
  width: 100%;
  height: calc(100% - #{$header-height});
  max-height: none;
  border-radius: 0px;
  z-index: 1500;
  background: white;
  flex-flow: column;
  align-items: flex-start;
  margin: 0px;

  .legend__events-label,
  .legend__actors-label {
    margin-top: 20px;
    margin-left: 0px;
  }

  // .legend__events,
  // .legend__actors {
  //   flex-flow: column;
  //   margin-top: 20px;
  //   margin-left: 0px;
  // }

  .legend__actor,
  .legend__event {
    margin-left: 20px;
    margin-top: 20px;
  }
}
